/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'components';
import { colors } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { SOFTWARE_PACKAGE_STATUS_COLORS } from 'common/constants';

const useStyles = makeStyles(theme => ({
  label: {
    marginRight: theme.spacing(1)
  },
  link: {
    cursor: 'pointer'
  }
}));

const MonitoredSoftwareVersions = props => {
  const classes = useStyles();

  const { softwareVersions } = props;

  const LABELS = {
    'xonar-cell-credentials': 'Creds',
    'xonar-mqtt-tls': 'MQTT TLS',
    'xonar-cell-os-apt': 'Cell OS',
    'xonar-cell-software': 'Cell Software',
    'xonar-cell-software-docker': 'Cell Software',
    'xonar-operator-middleware': 'Middleware',
    'xonar-api-credentials': 'Creds'
  };

  const Version = props => {
    const { softwareVersion } = props;

    return (
      <Label
        key={softwareVersion.name}
        color={
          SOFTWARE_PACKAGE_STATUS_COLORS[
            softwareVersion.softwarePackage?.status
          ]
        }
        className={clsx(
          classes.label,
          softwareVersion.softwarePackage?.id ? classes.link : ''
        )}
        onClick={() =>
          softwareVersion.softwarePackage?.id
            ? (window.location = `/software/packages/${softwareVersion.softwarePackage?.id}/summary`)
            : undefined
        }>
        {LABELS[softwareVersion.name]
          ? LABELS[softwareVersion.name]
          : softwareVersion.name}{' '}
        / {softwareVersion.version}
      </Label>
    );
  };

  return (
    <>
      {softwareVersions &&
        softwareVersions.map((softwareVersion, i) => (
          <Version key={i} softwareVersion={softwareVersion} />
        ))}
    </>
  );
};

MonitoredSoftwareVersions.propTypes = {
  softwareVersions: PropTypes.array
};

export default MonitoredSoftwareVersions;
